<template>
  <div id="container" v-if="fetch_data">

    <section id="mypage">
      <div class="wrap">

        <!-- 研修会 -->
        <div class="cts_box">
          <h2 class="sub_ttl"><span>研修会参加履歴</span></h2>
          <p class="lead_txt">
            現在参加中、または参加申し込み済みの研修会の一覧です。クリックで研修会の詳細ページを表示します。<br>
            各研修会の詳細および配信コンテンツについてはそれぞれの研修会詳細ページよりご確認ください。
          </p>

          <div class="event_box">
            <h3 class="min_ttl">現在<span>{{ fetch_data.join_events.length }}</span>の研修会に参加申し込み済みです</h3>
            <ul class="event_list">
              <li class="flex" v-bind:class="{'cancel': model.payment === 'payment_cancel'}" v-for="model in fetch_data.join_events" :key="model.category + model.id">
                <template v-if="model.category !== 'monthly_live'">
                  <router-link :to="{ name: 'WorkshopShow', params: {category: model.category, id: model.id} }">
                    <h3>{{ model.title }}</h3>
                    <div class="info flex">
                      <div class="date"><span class="day">{{ moment(model.open_date, 'YYYY/MM/DD') }}</span><span
                          class="time">{{ model.open_time }}</span></div>
                      <div class="task unpaid" v-if="model.payment === 'payment_cancel'">キャンセル</div>
                    </div>
                    <div class="contents">
                      <p v-html="model.list_text"></p>
                    </div>
                    <div v-if="model.category === 'camp_tour' || model.category === 'camp_camp'">
                      <div class="detail flex"><span class="tag">開催地</span>{{ model.place }}</div>
                    </div>
                    <div v-else>
                      <div class="detail flex" v-if="model.offline"><span class="tag">教室</span>{{ model.place }}</div>
                      <div class="detail flex" v-if="model.online"><span class="tag red">LIVE</span>オンライン</div>
                    </div>
                  </router-link>
                </template>
                <template v-else>
                  <router-link :to="{ name: 'GetsureiShow', params: {id: model.id} }">
                    <h3>{{ model.title }}</h3>
                    <div class="info flex">
                      <div class="date"><span class="day">{{ moment(model.open_date, 'YYYY/MM/DD') }}</span><span
                          class="time">{{ model.open_time }}</span></div>
                      <div class="task unpaid" v-if="model.payment === 'payment_cancel'">キャンセル</div>
                    </div>
                    <div class="contents">
                      <p v-html="model.list_text"></p>
                    </div>
                    <div class="detail flex">
                      <template v-if="model.held_type === 'held_type_temporary'">
                        <span class="tag">開催地</span>{{ model.relay_place }}
                      </template>
                      <template v-else>
                        <span class="tag">中継会場</span>{{ model.relay_place }}
                      </template>
                    </div>
                  </router-link>
                </template>
              </li>
            </ul>
          </div>

          <div class="button_wrap">
            <router-link :to="{ name: 'UsersCustomerAccount'}" class="submit arrow back light skeleton large">
              マイページに戻る
            </router-link>
          </div>
        </div>

      </div>
    </section>

  </div>
</template>

<script>

import Libraries from '@/components/Libraries'

export default {
  mixins: [Libraries],
  data() {
    return {
      fetch_data: null,
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}users/join_events.json`, {
            params: {
              page: this.$route.query.page
            }
          })
          .then(response => {
            this.fetch_data = response.data
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    }
  }
}
</script>
